import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import {useOutlet} from 'reconnect.js';
import * as AppActions from '../../AppActions';
const qs = require('query-string');

const pageSize = 20;

const Columns = () => [
  {
    title: '查詢結果',
    key: '#',
    render: (_, record) => {
      const date = new Date(record.checkout.created);
      return (
        <div
          style={{cursor: 'pointer'}}
          onClick={() => {
            if (record.order) {
              window.open(`/admin/order/?action=detail&id=${record.order.id}`);
            } else {
              window.open(
                `/admin/checkout/?action=detail&id=${record.checkout.id}`,
              );
            }
          }}>
          <div style={{color: '#888'}}>
            {record.order
              ? record.order.order_id
              : record.checkout.order_number}
          </div>
          <div>時間: {date.toLocaleString('sv')}</div>
          <div>
            買家: {record.checkout.buyer_name} / {record.checkout.buyer_phone} /{' '}
            {record.checkout.buyer_email}
          </div>
          <div>商品: {record.checkout.items.map((i) => i.name).join(',')}</div>
          <div>價格: ${record.checkout.total}</div>
        </div>
      );
    },
  },
];

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    required: ['startDate', 'days'],
    properties: {
      startDate: {
        title: '起始日期',
        type: 'string',
        format: 'date',
      },
      days: {
        title: '天數',
        type: 'number',
        enum: [1, 3, 7, 30, 100],
        enumNames: ['當日', '3日內', '1周內', '30日內', '100日內'],
      },
      sorting: {
        title: '排序',
        type: 'string',
        enum: ['-created', 'created'],
        enumNames: ['日期新到舊', '日期舊到新'],
      },
    },
  },
  uiSchema: {},
};

function getEnumName(field, value) {
  const idx = FormSpec.schema.properties[field].enum.indexOf(value);
  return FormSpec.schema.properties[field].enumNames[idx];
}

async function generateExcelFile(records) {
  if (!window.XLSX) {
    console.log('no XLSX');
    return;
  }

  try {
    AppActions.setLoading(true);
    const wb = window.XLSX.utils.book_new();
    const ws = window.XLSX.utils.aoa_to_sheet([
      [
        '訂單編號',
        '日期',
        '時間',
        '付款方式',
        '狀態',
        '買家',
        '電話',
        '電郵',
        '物流方式',
        '收件縣市',
        '收件區域',
        '收件地址',
        '總價',
        '商品',
      ],
      ...records.map(({order, checkout}) => {
        console.log('checkout', checkout);
        console.log('order', order);
        return [
          order?.order_id || checkout.order_number,
          new Date(checkout.created).toLocaleDateString('sv'),
          new Date(checkout.created).toLocaleTimeString('sv'),
          checkout.payment_subtype,
          order?.status || checkout.status || 'waiting',
          checkout.buyer_name,
          checkout.buyer_phone,
          checkout.buyer_email,
          checkout.logistics_type,
          checkout.buyer_city,
          checkout.buyer_district,
          checkout.buyer_address,
          checkout.total,
          checkout.items.map((i) => `${i.name}×${i.config.qty}`).join(','),
        ];
      }),
    ]);
    window.XLSX.utils.book_append_sheet(wb, ws, '訂單列表');
    const wbout = window.XLSX.write(wb, {
      bookType: 'xlsx',
      bookSST: false,
      type: 'array',
      cellStyles: true,
      bookImages: true,
    });
    const objUrl = URL.createObjectURL(
      new Blob([wbout], {type: 'application/octet-stream'}),
    );
    await AppActions.delay(600);
    Ant.message.success('成功創建下載連結');
    return objUrl;
  } catch (ex) {
    console.warn('generateExcelFile ex', ex);
  } finally {
    AppActions.setLoading(false);
  }
}

export default function AdminOrderExcelDownloadPage(props) {
  const {token: _token} = qs.parse(props.location.search);
  const [user] = useOutlet('user');
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [downloadUrl, setDownloadUrl] = React.useState(null);
  const [queryModalData, setQueryModalData] = React.useState({
    visible: false,
    data: {
      startDate: new Date().toISOString().split('T')[0],
      days: 1,
      sorting: '-created',
    },
  });
  const queryParams = queryModalData.data;
  const token = _token || (user && !user.tmp && user.token);

  const fetchData = React.useCallback(async () => {
    if (!token) {
      return;
    }

    AppActions.setLoading(true);
    try {
      const startTime = new Date(queryParams.startDate).getTime();
      const endTime = startTime + queryParams.days * 24 * 3600 * 1000;
      const query = {
        created: {
          $gt: startTime,
          $lte: endTime,
        },
      };

      const checkoutResp = await JStorage.fetchDocuments(
        'checkout',
        query,
        [queryParams.sorting],
        paging,
        null,
        {token},
      );

      const orderResp = await JStorage.fetchDocuments(
        'order',
        {
          order_number: {
            $in: checkoutResp.results.map((c) => c.order_number),
          },
        },
        ['-created'],
        paging,
        null,
        {token},
      );

      const mergedResp = [];
      for (const checkout of checkoutResp.results) {
        mergedResp.push({
          id: checkout.id,
          checkout,
          order: orderResp.results.find(
            (o) => o.order_number === checkout.order_number,
          ),
        });
      }

      setFetchResp({total: checkoutResp.total, results: mergedResp});
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
  }, [token, paging, queryParams]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <div className="content">
        <h1>訂單紀錄</h1>
        <section
          className="query"
          onClick={() => {
            setQueryModalData({data: queryParams, visible: true});
          }}>
          <h3>查詢參數</h3>
          <div>
            時間: 從 {queryParams.startDate} 起 {queryParams.days} 日
          </div>
          <div>排序: {getEnumName('sorting', queryParams.sorting)}</div>
        </section>

        <section className="actions">
          <Ant.Button
            onClick={async () => {
              if (fetchResp?.total) {
                const link = await generateExcelFile(fetchResp.results);
                if (link) {
                  setDownloadUrl(link);
                }
              } else {
                window.alert('無訂單');
              }
            }}>
            匯出Excel
          </Ant.Button>
          {downloadUrl && (
            <Ant.Button
              type="link"
              download={'訂單.xlsx'}
              href={downloadUrl}
              target="_blank">
              下載
            </Ant.Button>
          )}
        </section>

        <Ant.Table
          columns={Columns()}
          dataSource={fetchResp?.results || []}
          rowKey={'id'}
          pagination={{
            size: 'small',
            total: fetchResp?.total || 0,
            showSizeChanger: false,
            showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
            current: paging.offset / pageSize + 1,
            pageSize: pageSize,
            position: ['topLeft'],
            onChange: (page, pageSize) => {
              if (pageSize) {
                setPaging({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }
            },
          }}
        />
      </div>

      <QueryFormModal
        data={queryModalData}
        setData={setQueryModalData}
        fetchData={fetchData}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 100px 0;
  background-color: #eee;
  min-height: 100vh;

  & > .content {
    width: min(720px, 100% - 20px);
    margin-inline: auto;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 20px 10px;

    h1 {
      font-size: 24px;
    }

    section.query {
      border: 1px dashed #ccc;
      border-radius: 8px;
      padding: 12px;
      cursor: pointer;
      margin-bottom: 10px;
    }

    section.actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
`;

function QueryFormModal(props) {
  const {
    data: {data, visible},
    setData,
  } = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={visible}
      onOk={() => {
        setData({
          data,
          visible: false,
        });
      }}
      onCancel={() => {
        setData({
          data,
          visible: false,
        });
      }}
      destroyOnClose={true}>
      {visible && <QueryModalContent {...props} />}
    </Ant.Modal>
  );
}

function QueryModalContent(props) {
  const {
    data: {data: prevData},
    setData,
  } = props;

  return (
    <div style={{padding: 20}}>
      <h1>查詢參數</h1>

      <GenericForm
        {...FormSpec}
        instance={{...prevData}}
        onSubmit={async (formData, extValues) => {
          setData({visible: false, data: {...prevData, ...formData}});
        }}
      />
    </div>
  );
}
